import {useLayoutEffect, useRef, useState} from "react";
import moment from 'moment'
import {translate} from "../utils/Translate";

import {Set, Map} from 'immutable'

function isFilteredContainer(c, getChildContainers = c => [], isGoodContainer = () => true) {
    // console.log('isFilteredContainer | c = ', c);
    if (isGoodContainer(c)) {
        return true;
    }
    let childContainers = getChildContainers(c);
    // console.log('c, childContainers = ', c, childContainers);
    for (let i in childContainers) {
        let cc = childContainers[i];
        if (isFilteredContainer(cc, getChildContainers, isGoodContainer) == true) {
            return true;
        }
    }
    return false;
}

const ContainersHelper = {

    getFilteredContainersFromLeafsSet(allContainers, leafContainersIdsSet = Set()) {

    },

    getFilteredContainers(allContainers, modulesIds = []) {
        console.log('getFilteredContainers: allContainers, modulesIds = ', allContainers, modulesIds);
        // console.log('getFilteredContainers: allContainers = ', allContainers);
        let modulesIdsSet = modulesIds.reduce((st, m) => st.add(m), Set());
        let hasFromSet = c => ((c.modulesIds || []).filter(xx => modulesIdsSet.has(xx)).length > 0);
        let leafContainers = allContainers.filter(xx => hasFromSet(xx));
        console.log('leafContainers = ', leafContainers);
        let leafContainersSet = leafContainers.reduce((st, c) => st.add(c.id), Set());
        console.log('leafContainersSet = ', leafContainersSet);
        // let isGoodContainer = c => (leafContainersSet.has(c.id) || ((c.childrenIds || []).filter(xx => leafContainersSet.has(xx)) > -1));
        let isGoodContainer = c => {
            let f = (leafContainersSet.has(c.id));
            // console.log('isGoodContainer: c = ', c, f);
            return f;
        };
        let containersMap = allContainers.reduce((mp, c) => mp.set(c.id, c), Map());
        let getChildContainers = c => (c.childrenIds || []).map(cId => containersMap.get(cId)).filter(x => (x != undefined));
        let arr = [];
        for (let i in allContainers) {
            let c = allContainers[i];
            if (isFilteredContainer(c, getChildContainers, isGoodContainer)) {
                // console.log('getFilteredContainers: adding container: c = ', c);
                arr.push(c);
            }
        }
        console.log('==>>> arr = ', arr);
        return arr;
    },

    getSearchContainersMap(allContainersMap, searchModulesIds = []) {
        console.log('getSearchContainersMap: searchModulesIds = ', searchModulesIds);
        let containers = this.getFilteredContainers(allContainersMap.toArray(), searchModulesIds);
        console.log('getSearchContainersMap: filtered = containers = ', containers);
        return containers.reduce((mp, c) => mp.set(c.id, c), Map());
    },

    getLimboContainersAndModulesMap(allContainersMap, allModulesMap = Map()) {
        console.log('getLimboContainersAndModulesMap: allContainersMap = ',  allContainersMap);
        let modules = allModulesMap.toArray().filter(x => (x?.isLimbo == true));
        console.log('getLimboContainersAndModulesMap: modules = ', modules);
        let modulesIds = modules.map(x => x.id);
        let containers = this.getFilteredContainers(allContainersMap.toArray(), modulesIds);
        console.log('getSearchContainersMap: filtered = containers = ', containers);
        let cMap = containers.reduce((mp, c) => mp.set(c.id, c), Map());
        return {
            containersMap: cMap,
            modules: modules
        };
    },

};

export default ContainersHelper;
