import * as types from '../ActionTypes.js'
import toast from 'react-hot-toast'

export function copyToBuffer(type, content) {
    try {
        if (content != undefined) {
            toast('Скопировано в буфер обмена');
        }
    } catch (exc) {

    }
    console.log('copyToBuffer: type = ', type, 'content = ', content);

    let d = {
        EXERCISE: {
            type: types.COPY_EXERCISE_TO_BUFFER,
            exerciseId: content
        },
        DROCHKA_EXERCISE: {
            type: types.COPY_DROCHKA_EXERCISE_TO_BUFFER,
            drochkaExerciseId: content
        },
        SLIDE_ITEM: {
            type: types.COPY_SLIDE_ITEM_TO_BUFFER,
            slideItem: content
        },
        TEST: {
            type: types.COPY_TEST_TO_BUFFER,
            testId: content
        },
        TREADMILL: {
            type: types.COPY_TREADMILL_TO_BUFFER,
            treadmillId: content
        }
    }[type];
    if (d != undefined) {
        return d;
    }
    return {}
}